import React from 'react';
import { graphql, navigate } from 'gatsby';
import Layout from 'src/components/layouts/default';
import BlockQualifiedReferralForm from 'src/components/blocks/blockQualifiedReferralForm.js';
import * as classes from './qualifiedreferral.module.scss';

export const query = graphql`
  query {
    datoCmsStart {
      qualifiedReferralTitle
      qualifiedReferralLede {
        blocks
        links
        value
      }
      qualifiedReferralPatientHeader
      qualifiedReferralUploadDirections
      qualifiedReferralReferrerHeader
      qualifiedReferralButtonText
      qualifiedReferralCompleteContent {
        blocks
        links
        value
      }
    }
  }
`;

const QualifiedReferralPage = ({ data, location }) => (
  <Layout location={location} pageTitle={data.datoCmsStart.qualifiedReferralTitle}>
    <div className={classes.body}>
      <BlockQualifiedReferralForm
        preformContent={data.datoCmsStart.qualifiedReferralLede}
        patientHeader={data.datoCmsStart.qualifiedReferralPatientHeader}
        uploadDirections={data.datoCmsStart.qualifiedReferralUploadDirections}
        referrerHeader={data.datoCmsStart.qualifiedReferralReferrerHeader}
        buttonText={data.datoCmsStart.qualifiedReferralButtonText}
        onComplete={() =>
          navigate('/complete', {
            state: {
              pageTitle: data.datoCmsStart.qualifiedReferralTitle,
              body: data.datoCmsStart.qualifiedReferralCompleteContent,
            },
          })
        }
      />
    </div>
  </Layout>
);

export default QualifiedReferralPage;
